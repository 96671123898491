import {createSelector, createSlice} from "@reduxjs/toolkit";
import {fetchWithMessages} from "../utils/api/fetchWithMessages";
import {getInitializedRegistration, getRegistrationMetadata} from "../utils/registrationsUtilities";
import {sessionsActions} from "./sessions";
import dayjs from "dayjs";
import {t} from "i18next";
import {loadEntityFromBackend} from "../utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";

export const registrationsSlice = createSlice({
  name: "registrations",
  initialState: {
    list: [],
    init: false,
    current: undefined,
    authenticated: undefined,
    registrationSuccessfulModalOpen: false,
  },
  reducers: {
    addToList: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    updateInList: (state, action) => {
      state.list = [action.payload, ...state.list.filter((i) => i._id !== action.payload._id)];
    },
    initContext: (state, action) => {
      state.init = action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list || [];
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    changeCurrent: (state, action) => {
      state.current = {
        ...state.current,
        ...action.payload,
      };
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    changeAuthenticated: (state, action) => {
      state.authenticated = {
        ...state.authenticated,
        ...action.payload,
      };
    },
    setRegistrationSuccessfulModalOpen: (state, action) => {
      state.registrationSuccessfulModalOpen = action.payload;
    },
  },
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await loadListFromBackend(
      "registrations",
      projectId,
      state.registrations.init,
      () => dispatch(registrationsActions.initContext(projectId)),
      (data) => dispatch(registrationsActions.initList({list: data, project: projectId}))
    );
  },
  loadCurrent: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const currentUserId = state.currentUser.user._id;
    const authenticatedUserId = state.currentUser.authenticatedUser._id;

    return loadEntityFromBackend(
      "registrations",
      "current",
      projectId,
      state.registrations.current,
      null,
      async (loadedRegistration) => {
        // If there is a r, but booked is not defined yet, initialize it to false.
        // We also then consider that it's the first visit of the person on the app
        if (!loadedRegistration.booked) {
          loadedRegistration.booked = false;
          loadedRegistration.firstVisit = true;
        }

        // Update and initialize the current registration
        await dispatch(registrationsActions.setCurrentWithMetadata(loadedRegistration, true, true));

        // Also change the authenticated
        if (loadedRegistration.user._id === authenticatedUserId) {
          await dispatch(registrationsActions.changeAuthenticated(loadedRegistration));
        }
      },
      {
        notFoundAction: () => {
          // If the current registration is not yet initialized, initialize it
          if (!state.registrations.current?._id) {
            const newRegistration = {
              _id: "new",
              availabilitySlots: [],
              formAnswers: {},
              booked: false,
              firstVisit: true,
              user: currentUserId,
              project: projectId,
            };
            dispatch(registrationsActions.setCurrentWithMetadata(newRegistration, true));
            dispatch(registrationsActions.changeAuthenticated(newRegistration));
          }
        },
        silentFailIfNotFound: true,
      }
    );
  },
  register: (silentProgressMessage) => async (dispatch, getState) => {
    const state = getState();

    const currentProject = state.currentProject.project;
    const currentRegistration = state.registrations.current;

    const registrationIsNew = currentRegistration._id === "new";

    try {
      const updatedCurrentRegistration = await fetchWithMessages(
        `projects/${currentProject._id}/registrations/` +
          (registrationIsNew ? "" : currentRegistration._id),
        {
          method: registrationIsNew ? "POST" : "PATCH", // Update, or create if there is no registration id
          body: {...state.registrations.current, booked: true},
        },
        {
          200: currentRegistration.everythingIsOk
            ? currentRegistration.inDatabase.everythingIsOk && t("common:modificationsSaved")
            : silentProgressMessage
            ? null
            : {
                type: "warning",
                duration: 5,
                message: t("registrations:messages.savedProgress"),
              },
        },
        t("registrations:messages.defaultError")
      );

      if (currentRegistration.everythingIsOk && !currentRegistration.inDatabase.everythingIsOk) {
        dispatch(registrationsActions.setRegistrationSuccessfulModalOpen(true));
      }

      // Update and initialize the current registration
      dispatch(registrationsActions.setCurrentWithMetadata(updatedCurrentRegistration, true));
      // Update in list
      await dispatch(registrationsActions.updateInList(updatedCurrentRegistration));
    } catch (e) {
      return Promise.reject();
    }
  },
  unregister: () => async (dispatch, getState) => {
    const state = getState();

    const currentProject = state.currentProject.project;
    const currentRegistration = state.registrations.current;

    try {
      const updatedCurrentRegistration = await fetchWithMessages(
        `projects/${currentProject._id}/registrations/${currentRegistration._id}`,
        {
          method: "PATCH",
          body: {
            booked: false,
            availabilitySlots: [],
            formAnswers: {},
            sessionsSubscriptions: [],
            teamsSubscriptions: [],
            steward: null,
            helloAssoTickets: [],
            customTicketingTickets: [],
          },
        },
        {200: t("registrations:messages.unregistrationSuccessful")},
        t("registrations:messages.defaultError")
      );

      // Update and initialize the current registration
      dispatch(registrationsActions.setCurrentWithMetadata(updatedCurrentRegistration, true));
      // Update in list
      dispatch(registrationsActions.updateInList(updatedCurrentRegistration));
      // Reinitialize the sessions list to remove
      dispatch(sessionsActions.updateFilteredList());
    } catch (e) {
      return Promise.reject();
    }
  },
  setCurrentWithMetadata:
    (payload, initializeDatabaseMetadata = false, resetData = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const registration = resetData ? payload : {...state.registrations.current, ...payload};
      await dispatch(
        registrationsActions.setCurrent(
          getInitializedRegistration(
            registration,
            state.currentProject.project,
            initializeDatabaseMetadata
          )
        )
      );
    },
  getPdfPlanning: () => async (dispatch, getState) => {
    const state = getState();

    try {
      return await fetchWithMessages(
        `projects/${state.currentProject.project._id}/pdf/registrationPlanning/${state.registrations.current._id}`,
        {isBlob: true, method: "GET"},
        {},
        false,
        true
      );
    } catch {
      return Promise.reject();
    }
  },
  hasNotShownUpInSession:
    (registrationId, sessionId, hasNotShownUp) => async (dispatch, getState) => {
      const state = getState();

      const currentProject = state.currentProject.project;
      const sessionEditing = state.sessions.editing;

      const updatedCurrentRegistration = await fetchWithMessages(
        `projects/${currentProject._id}/registrations/${registrationId}/noShow/${sessionId}`,
        {
          method: "POST",
          body: {hasNotShownUp},
        },
        {200: "Modification réussie."}
      );

      // Update in list
      const sessionWithUpdatedParticipants = {
        ...sessionEditing,
        participants: sessionEditing.participants.map((participant) =>
          participant._id === registrationId ? updatedCurrentRegistration : participant
        ),
      };
      console.log(sessionEditing.participants, sessionWithUpdatedParticipants.participants);
      await dispatch(sessionsActions.setEditing(sessionWithUpdatedParticipants));
    },
  transferTicket:
    ({ticketId, toEmail}) =>
    async (dispatch, getState) => {
      const state = getState();
      const currentProject = state.currentProject.project;

      try {
        // TODO make sure to persist the registration form values before transferring a ticket

        // Then, transfer the ticket
        await fetchWithMessages(
          `projects/${currentProject._id}/ticketing/transfer`,
          {
            method: "POST",
            noResponseData: true,
            body: {
              ticketId,
              fromEmail: state.registrations.current.user.email,
              toEmail,
            },
          },
          {
            200: {
              type: "success",
              message: t("registrations:ticketing.transfer.messages.success", {toEmail}),
              duration: 8,
            },
            404: t("registrations:ticketing.transfer.messages.notFound"),
          }
        );

        // And reload the registration properly
        dispatch(registrationsActions.loadCurrent());
      } catch (e) {
        // Do nothing
      }
    },
  removeTicketFromRegistration: (ticketId) => async (dispatch, getState) => {
    const state = getState();
    const currentProject = state.currentProject.project;
    const currentRegistration = state.registrations.current;
    const ticketsField = `${currentProject.ticketingMode}Tickets`;
    const existingTickets = currentRegistration[ticketsField] || [];
    dispatch(
      registrationsActions.setCurrentWithMetadata({
        [ticketsField]: existingTickets.filter((ticket) => ticket.id !== ticketId),
      })
    );
  },
  addTicketToRegistration: (ticketId) => async (dispatch, getState) => {
    const state = getState();
    const currentProject = state.currentProject.project;
    if (!currentProject.ticketingMode) return;
    const currentRegistration = state.registrations.current;
    const ticketsField = `${currentProject.ticketingMode}Tickets`;
    const existingTickets = currentRegistration[ticketsField] || [];

    const {firstSlotIsOk, formIsOk} = currentRegistration;

    const allTheRestOfTheRegistrationIsOk = firstSlotIsOk && formIsOk;
    try {
      const checkedTicket = await fetchWithMessages(
        `projects/${currentProject._id}/ticketing/check/${ticketId}`,
        {method: "POST"},
        {
          // Don't display success message if we know the registration will be complete and saved juste after (and the user will be notified)
          200: !allTheRestOfTheRegistrationIsOk && {
            type: "success",
            duration: 4,
            message: t("registrations:ticketing.messages.ticketAdded"),
          },
          405: {type: "error", message: t("registrations:ticketing.messages.alreadyUsedTicket")},
          404: {type: "error", message: t("registrations:ticketing.messages.ticketNotFound")},
        },
        undefined,
        true,
        undefined
      );

      await dispatch(
        registrationsActions.setCurrentWithMetadata({
          [ticketsField]: [...existingTickets, checkedTicket],
        })
      );

      // only register if the availabilities are correct, else we can't
      firstSlotIsOk && (await dispatch(registrationsActions.register(true)));

      // Return true if success
      return true;
    } catch (e) {
      /* do nothing if ticket check fails */
    }
  },
};

export const registrationsSelectors = {
  selectCurrent: (state) => state.registrations.current,
  selectAuthenticated: (state) => state.registrations.authenticated,
  selectList: (state) => state.registrations.list,
  selectRegistrationSuccessfulModalOpen: (state) =>
    state.registrations.registrationSuccessfulModalOpen,
};

registrationsSelectors.selectListWithMetadata = createSelector(
  [registrationsSelectors.selectList, (state) => state.currentProject.project],
  (registrations, project) => {
    console.log("hey");
    return registrations?.map((r) => ({
      ...r,
      ...getRegistrationMetadata(r, project),
      teamsSubscriptionsNames: r.teamsSubscriptions?.map((t) => t.name).join(", "),
      arrivalDateTime: [...(r.availabilitySlots || [])].sort(
        (a, b) => dayjs(a.start) - dayjs(b.start)
      )?.[0]?.start,
      departureDateTime: [...(r.availabilitySlots || [])].sort(
        (a, b) => dayjs(b.end) - dayjs(a.end)
      )?.[0]?.end,
    }));
  }
);

export const registrationsReducer = registrationsSlice.reducer;

export const registrationsActions = {
  ...registrationsSlice.actions,
  ...asyncActions,
};
